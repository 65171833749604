<template>
  <div>
    <el-dialog
      class="menu-update-dialog"
      :title="lang_data == 1 ? '设计方案' : 'Design Scheme'"
      :visible="true"
      :before-close="cancel"
      width="1000px"
      :close-on-click-modal="false"
    >
      <StlFile :datainfo="datainfo" width="960" :height="boxheight"></StlFile>

      <div slot="footer" style="text-align: center">
        <el-button size="small" @click="cancel">{{
          lang_data == 1 ? "关闭" : "Shut down"
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import StlFile from "./stlFile";
export default {
  name: "DesignBox",
  components: {
    StlFile,
  },
  props: ["datainfo"],
  mounted() {},
  computed: {
    boxheight() {
      return document.body.clientHeight * 0.7;
    },
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
